.add-to-cart-popup__content {
    margin: 2rem 0;
}

.add-to-cart-popup__header {
    background-color: var(--color-green-4);
    padding: 1rem 3rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    &.-error {
        background: var(--color-red-7);

        .add-to-cart-popup__title-text {
            color: var(--color-red-1);
        }

        .svg-icon.add-to-cart-popup__check-icon {
            fill: var(--color-red-1);
            color: var(--color-red-1);
        }
    }
}

.add-to-cart-popup__title {
    display: flex;
    color: var(--color-green-3);
    font-size: 1.7rem;
    line-height: 2rem;
    position: relative;
}

.add-to-cart-popup__title-text {
    flex: 1;
}

.add-to-cart-popup__check-icon {
    position: absolute;
    left: -2rem;
}

.add-to-cart-popup__item {
    display: flex;
    align-items: center;
}

.add-to-cart-popup__item-img {
    display: none;
}

.add-to-cart-popup__item-title {
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--color-grey-3);
    margin-bottom: .4rem;
}

.add-to-cart-popup__item-producer {
    font-size: 1.2rem;
    line-height: 1rem;
    color: var(--color-grey-4);
    text-transform: uppercase;
}

.add-to-cart-popup__actions {
    width: 100%;

    > .button {
        padding: 1.25rem;
        width: 100%;

        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
}

.add-to-cart-popup__related {
    padding: 0 2rem;
}

.add-to-cart-popup__related-title {
    font-family: var(--font-wotfard);
    margin: 0 0 1rem;
}

.add-to-cart-popup__related-slider {
    margin: 0;
}

.add-to-cart-popup__packages {
    padding: 0 2rem;
}

.add-to-cart-popup__packages-title {
    font-size: 2rem;
    font-weight: 400;
    color: var(--color-red-3);
}

.add-to-cart-popup__packages-subtitle {
    font-size: 2rem;
    font-weight: 400;
}

.add-to-cart-popup__luigibanner {
    padding: 1.5rem;
    margin: 1.5rem 3rem;
}

.add-to-cart-popup__loader {
    display: flex;
    align-content: center;
    justify-content: center;

    img {
        max-height: 25rem;
    }
}

@media (--screen-from-sm) {
    .add-to-cart-popup {
        padding: 0 6rem;
    }

    .add-to-cart-popup__inner {
        max-width: 71.6rem;
        border-radius: .8rem;
        height: fit-content;
    }

    .add-to-cart-popup__related {
        //margin: 3rem 0 2.6rem;
    }
}

@media (--screen-from-md) {
    .add-to-cart-popup__header {
        grid-template-columns: auto 22.3rem;
        padding: 2rem;
    }

    .add-to-cart-popup__title {
        grid-column: 1/3;
        font-size: 2rem;
        line-height: 1;
        gap: 1rem;
    }

    .add-to-cart-popup__actions {
        max-width: 22.3rem;
    }

    .add-to-cart-popup__item-img {
        display: block;
        width: 5.2rem;
        height: 9.8rem;

        img {
            max-width: 6.4rem;
            max-height: 10rem;
        }
    }

    .add-to-cart-popup__item-content {
        margin-left: 2.7rem;
    }

    .add-to-cart-popup__check-icon {
        position: static;
    }
}
