.age-consent-popup__content {
    margin: 2rem 0;
}

.age-consent-popup__header {
    padding: 1rem 3rem;
}

.age-consent-popup__title {
    display: flex;
    font-size: 1.7rem;
    line-height: 2rem;
}

.age-consent-popup__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    width: 100%;
    margin: 3rem 0 0;

    form {
        width: auto;
    }
}

@media (--screen-from-sm) {
    .age-consent-popup {
        padding: 2rem 6rem;
    }
}

@media (--screen-from-md) {
    .age-consent-popup__header {
        padding: 2rem;
    }

    .age-consent-popup__title {
        font-size: 2rem;
        line-height: 1;
    }
}
